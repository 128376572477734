<template>
  <div class="wrapper">
    <div class="wrapper__content">
      <div class="left">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltipIcon, attrs }">
            <v-icon
              v-on="{ ...activatorMenuProp, ...tooltipIcon }"
              v-bind="attrs"
              @click="$emit('openMenu')"
            >
              {{ isMenuOpen ? "$close_rounded" : "$filter_list_rounded" }}</v-icon
            >
          </template>
          <div class="tooltip-chip">
            <span>Opções de filtros</span>
          </div>
        </v-tooltip>
      </div>

      <div class="middle">
        <v-sheet
          class="mx-auto"
          max-width="100%"
          style="background: transparent !important"
          @click.stop
        >
          <v-slide-group multiple show-arrows>
            <v-slide-item v-for="item in chipsList" :key="item.id">
              <v-tooltip
                :bottom="item.chip_computed_labels.length >= 200"
                :top="item.chip_computed_labels.length < 200"
                style="border-radius: 25px !important"
              >
                <template #activator="{ on: tooltip, attrs }">
                  <!-- CHIP DE INFORMAÇÕES -->
                  <div
                    style="max-width: 200px"
                    v-on="{ ...tooltip, ...activatorMenuProp }"
                    v-bind="attrs"
                    class="chip mx-1"
                    :style="{
                      backgroundColor: item.chip_type == 'interval' ? '#14AE5C' : '',
                    }"
                    @click.stop="$emit('click:chip', item)"
                  >
                    <div class="left">
                      <span>{{ item.chip_title }} =</span>

                      <strong>{{ item.chip_computed_labels || "sem info" }}</strong>
                    </div>

                    <div class="right">
                      <v-icon
                        v-if="item.chip_type != 'interval'"
                        color="#ffff"
                        style="font-size: 1rem !important"
                        @click.self.stop="$emit('removeFilter', item)"
                      >
                        $remove_circle_outline_rounded
                      </v-icon>
                    </div>
                  </div>
                </template>
                <div class="tooltip-chip">
                  <span>{{ item.chip_title }} =</span>
                  <span>{{ item.chip_computed_labels }}</span>
                </div>
              </v-tooltip>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <div class="right">
        <div class="d-flex align-center justify-center">
          <div class="delete-icon">
            <z-tooltip>
              <template #activator="{ on }">
                <z-icon
                  v-ripple
                  style="cursor: pointer"
                  color="primary"
                  size="21"
                  v-on="on"
                  @click.stop="removeAllFilters"
                >
                  $delete_forever_rounded
                </z-icon>
              </template>
              Remover todos os filtros
            </z-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require("lodash");
export default {
  components: {},
  props: {
    activatorMenuProp: { type: Object },
    isMenuOpen: { type: Boolean, default: false, required: true },
    // filters: { type: Array, default: null, required: true },
  },
  watch: {
    filters: {
      handler(nv) {
        this.filterList = _.cloneDeep(nv) || [];
      },
    },
    deep: true,
  },
  data() {
    return {
      filterList: [],
      model: null,
    };
  },
  methods: {
    removeAllFilters() {
      this.$emit("remove-all");
    },
  },
  computed: {
    chipsList() {
      // console.log('updated')
      return this.$store.getters["app/$chipsFilterList"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.wrapper {
  &__content {
    border: 1px solid grey;
    border-radius: 13px;
    padding: 0.1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .middle {
      flex: 2 1 100%;
      max-width: 1400px;

      @media (min-width: 1550px) {
        max-width: 98% !important;
        flex: 2 1 100%;
      }
    }
  }
}

.chip {
  background: #1976d2;
  color: #ffffff;
  padding: 0.05rem 0.35rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border-radius: 9px;
  .left {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      font-size: 0.55rem;
    }
    strong {
      font-size: 0.6rem;
    }
  }
}

.tooltip-chip {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  span {
    font-size: 0.7rem;

    &:nth-child(2) {
      font-weight: 600 !important;
    }
  }
}
</style>
