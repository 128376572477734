<template>
  <div class="chip" v-on="chipPropagation" :style="{  backgroundColor: chipInfo.chip_type == 'interval' ? '#14AE5C' : '',}" @click.stop="$emit('click', chipInfo)">
    <div v-if="!isEditingFiltersProp" class="left">
      <span>{{ chipInfo.chip_title }} =</span>

      <strong>{{ chipInfo.chip_computed_labels }}</strong>
    </div>

    <div v-if="isEditingFiltersProp" class="left">
      <strong>{{ chipInfo.chip_child_label }}</strong>
    </div>

    <div v-if="!isEditingProp && chipInfo.chip_type != 'interval'" class="right">
      <v-icon color="#ffff" small @click="$emit('remove-chip', chipInfo)"
        >$remove_circle_outline_rounded</v-icon
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chipInfo: { type: Object, default: null, required: true },
    chipPropagation: { type: Object },
    isEditingProp: { type: Boolean, default: false },
    isEditingFiltersProp: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  background: #1976d2;
  color: #ffffff;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 10px;
  .left {
    text-align: left;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      font-size: 0.65rem;
    }
    strong {
      font-size: 0.75rem;
      word-wrap: break-word;
    }
  }

  & > div {
    // border: 3px solid;
  }
}
</style>
