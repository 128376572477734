<template>
  <div>
    <v-menu
      v-model="showMenu"
      ref="menu"
      offset-y
      bottom
      min-width="300"
      height="500"
      :close-on-content-click="false"
      :close-on-click="closeModalOnContentClick"
      eager
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <FilterInput
          v-on="menu"
          v-bind="attrs"
          :activator-menu-prop="menu"
          :filters="chipsList"
          :is-menu-open="showMenu"
          @click:chip="onChipClick"
          @remove-all="onRemoveAllFilters"
          @openMenu="showMenu = !showMenu"
          @removeFilter="onRemoveFilter"
        />
      </template>

      <FilterCard
        :tempaltesListProp="tempaltesListProp"
        :journeysListProp="journeysListProp"
        :intervalFiltersListProp="intervalFiltersListProp"
        :filter-chips-list-prop="chipsList"
        :filterOptionsProp="filterOptionsProp"
        :key="cardKey"
        @close="showMenu = false"
        @remove-chip="onRemoveFilter"
        @create-chip-filter="onCreateFilterChip"
        @update-chip="onUpdateChip"
        @clicked-chip="onChipSelectedClick"
        @block-close-on-click="shouldCloseContentOnClick"
        @remove-all-filters="onRemoveAllFilters"
      />
    </v-menu>

    <v-dialog v-model="deleteChipDialog" width="600" :key="dialogDeleteKey">
      <CardDeleteChip
        @close="deleteChipDialog = false"
        @remove-child-chip="onRemoveChildChip"
      />
    </v-dialog>
  </div>
</template>

<script>
import CardDeleteChip from "./cardDeleteChip.vue";
import FilterCard from "./filterCard.vue";
import FilterInput from "./filterInput.vue";
export default {
  components: {
    FilterInput,
    FilterCard,
    CardDeleteChip,
  },
  props: {
    journeysListProp: { type: Array, default: null, required: false },
    tempaltesListProp: { type: Array, default: null, required: false },
    intervalFiltersListProp: { type: Array, default: null, required: false },
    filterOptionsProp: { type: Array, default: null, required: false },
  },
  data() {
    return {
      closeModalOnContentClick: true,
      deleteChipDialog: false,
      showMenu: false,
      cardKey: 0,
      dialogDeleteKey: 0,
      inputKey: 0,
    };
  },
  computed: {
    chipsList() {
      return this.$store.getters["app/$chipsFilterList"];
    },
  },
  watch: {
    showMenu(nv) {
      this.cardKey++;
      if (!nv) this.$store.dispatch("app/RESET_CHIP_TO_EDIT");
    },
    deleteChipDialog() {
      this.dialogDeleteKey++;
    },
  },
  methods: {
    shouldCloseContentOnClick(e) {
      this.closeModalOnContentClick = e;
    },
    onChipSelectedClick(e) {
      if (e.chip_type != "interval") this.deleteChipDialog = true;
    },

    onCreateFilterChip(chip) {
      this.$store.dispatch("app/CREATE_FILTER_CHIP", chip);
      this.showMenu = false;
      this.$emit("chip-created", this.chipsList);
    },

    onChipClick(chip) {
      this.$store.dispatch("app/SET_CHIP_TO_EDIT", chip);
    },

    onRemoveAllFilters() {
      this.$store.dispatch("app/RESET_FILTER_CHIP_LIST");
      this.$emit("remove-all-chips");
    },

    onRemoveFilter(chip) {
      this.$store.dispatch("app/REMOVE_FILTER_CHIP_ITEM", chip);
      this.$emit("remove-single-chip", this.chipsList);
    },

    onUpdateChip(chip) {
      this.$store.dispatch("app/UPDATE_CHIP", chip);
      this.showMenu = false;
      this.$emit("update-chip", this.chipsList);
    },

    onRemoveChildChip(chip) {
      this.$store.dispatch("app/REMOVE_CHILD_CHIP_ON_FATHER", chip);
      this.$emit("remove-child-chip", this.chipsList);
    },
  },
};
</script>

<style lang="scss" scoped></style>
