<template>
  <v-card
    elevation="2"
    shaped
    class="mx-2 my-2 py-2 info-card d-flex align-center justify-center"
    :style="{ borderLeft: `5px solid ${borderColorProp}` }"
  >
    <section class="info-card__content">
      <div class="info-card__content__left">
        <div class="icon" :style="{ backgroundColor: mainColorProp }">
          <v-icon
            class=""
            color="white"
            style="
              display: block;
              text-align: center;
              margin: 0px !important;
              margin-top: 10px !important;
            "
            >{{ icon }}</v-icon
          >
        </div>
      </div>

      <div class="info-card__content__right">
        <v-row class="mb-1" justify="center">
          <span style="font-size: 0.69rem; font-weight: 700">
            <slot name="title">Título</slot>
          </span>
        </v-row>
        <v-row class="mt-0" justify="center">
          <span
            :style="{
              color: mainColorProp,
              textAlign: 'center',
              fontSize: '1.15rem',
            }"
          >
            <slot name="value">0</slot>
          </span>
        </v-row>
      </div>
    </section>

    <div class="dot" style="background: none"></div>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: "$groups" },
    mainColorProp: { type: String, default: "#0273c3" },
    borderColorProp: { type: String, default: "##ffce4d" },
  },
};
</script>

<style lang="scss" scoped>
.info-card {
  position: relative;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;

    &__left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .icon {
        height: 50px;
        width: 50px;
        background-color: rgb(246, 189, 2);
        border-radius: 30px;
      }
    }

    &__right {
      flex: 1;
    }

    &__right {
      flex: 3;
    }

    & > div {
      padding: 0.33rem;
    }
  }

  .dot {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 100% !important;
    top: 10px;
    left: 10px;
  }
}
</style>
