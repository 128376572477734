<template>
  <div class="wrapper">
    <div class="wrapper__header">
      <span>EDITAR FILTROS DE {{ title }}</span>
      <v-icon @click="$emit('close')"> $close_rounded</v-icon>
    </div>

    <div class="wrapper__body">
      <div class="wrapper__body__list">
        <template v-for="item in currentChip.childs || []">
          <div class="item" :key="item.id">
            <FilterChip
              :chip-info="item"
              :is-editing-filters-prop="true"
              @remove-chip="removeChip"
            />
          </div>
        </template>
      </div>
    </div>

    <div class="wrapper__footer d-flex justify-end">
      <z-btn text="Fechar" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import { FILTERS_ENUM } from "./utils";
import FilterChip from "./filterChip.vue";
export default {
  components: {
    FilterChip,
  },
  computed: {
    title() {
      return FILTERS_ENUM[this.currentChip.chip_type].toUpperCase() || "";
    },
  },
  data() {
    return {
      currentChip: this.$store.getters["app/$currentEditChip"],
    };
  },
  methods: {
    removeChip(evt) {
      this.$emit("remove-child-chip", evt);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  padding: $z-s-1;
  border-radius: 10px;
  background: #ffffff;

  &__body {
    height: 50vh;
    margin: 0.5rem 0;
    overflow-y: auto;
    @include trackScrollBar;
    padding: 0.5rem;

    &__list {
      max-height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
}
</style>
